import { eventType } from '@iheartradio/web.analytics';

import { analytics } from './create-analytics';

export const trackClick = (pageName: string, action: string) => {
  analytics.track({
    type: eventType.enum.Click,
    data: {
      pageName,
      window: {
        location: {
          href: window.location.href,
        },
      },
      event: {
        location: `${pageName}|${action}`,
      },
    },
  });
};
